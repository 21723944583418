import {SERVER} from "../constants";
import {stringify} from "query-string";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";

export function getManyReference(resource, params) {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  console.log("getManyReference", resource, params);

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify({
      ...params.filter,
      [params.target]: params.objectId,
    }),
  };
  const url = `${SERVER}/${MAP_URL[resource]}?${stringify(query)}`;
  return HttpClient(url).then(({headers, json}) => {
    return {
        data: json,
        total: parseInt(headers.get("X-Total-Count").split("/").pop(), 10),
      };
    }
  );
}
