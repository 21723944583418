import React from "react";
import {
    Datagrid,
    ArrayField,
    DateField,
    Filter,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceField,
    SelectInput,
    SimpleShowLayout,
    TextField,
    ReferenceInput,
    TextInput, UrlField, required, BooleanInput, BooleanField,
} from "react-admin";
import EditView from "../../components/EditView";
import CreateView from "../../components/CreateView";
import ShowView from "../../components/ShowView";
import Emoji from "../../components/Emoji";

const BlogFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput alwaysOn label="Blog category" source="blogCategoryId" reference="blogCategory" sort={{field: 'title', order:'ASC'}}>
      <SelectInput optionText="title" alwaysOn/>
    </ReferenceInput>
  </Filter>
);

export const BlogList = (props) => (
  <List {...props} filters={<BlogFilter/>} perPage={60}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ImageField source="icon"/>
      <TextField source="title"/>
      <TextField source="order"/>
      <BooleanField source="isPremium"/>
      <ReferenceField link="show" label="Blog category" source="blogCategoryId" reference="blogCategory" >
        <TextField source="title"/>
      </ReferenceField>
    </Datagrid>
  </List>
);

export const BlogEdit = (props) => (
  <EditView {...props} perPage={10}>
    <ReferenceInput label="Blog category" validate={required()} source="blogCategoryId" reference="blogCategory">
      <SelectInput optionText="title" alwaysOn/>
    </ReferenceInput>
    <TextInput source="title" validate={required()}/>
    <TextInput source="referenceUrl" validate={required()}/>
    <NumberInput source="order" validate={required()}/>
    <Emoji source="description" label="Description" />
    <BooleanInput source="isPremium" validate={required()} />
    <ImageInput source="pictures"  label="Image" accept="image/*">
      <ImageField source="src" title="title"/>
    </ImageInput>
    <TextInput source="icon" validate={required()}/>
  </EditView>
);

export const BlogShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <ImageField source="icon"/>
      <TextField source="objectId"/>
      <TextField source="title"/>
      <TextField source="description"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <UrlField source="referenceUrl"/>
      <TextField source="order"/>
      <BooleanField source="isPremium" label="Is Premium"/>
      <ReferenceField link="show" label="Blog category" source="blogCategoryId" reference="blogCategory" >
        <TextField source="title"/>
      </ReferenceField>
    </SimpleShowLayout>
  </ShowView>
);

export const BlogCreate = (props) => (
  <CreateView {...props}>
    <ReferenceInput label="Blog category" source="blogCategoryId" validate={required()} reference="blogCategory">
      <SelectInput optionText="title"  alwaysOn/>
    </ReferenceInput>
    <TextInput source="title" validate={required()}/>
    <TextInput source="referenceUrl" validate={required()}/>
    <NumberInput source="order" validate={required()}/>
    <Emoji source="description" label="Description" />
    <BooleanInput defaultValue={false} source="isPremium" validate={required()} />
    <ImageInput source="pictures" validate={required()} label="Image" accept="image/*">
      <ImageField source="src" validate={required()} title="title"/>
    </ImageInput>
  </CreateView>
);
