export function planMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      adminSymptoms: resource.symptoms.map((item) => ({symptoms: item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdatePlanId(json) {
  return {
    data: {
      ...json.result,
      id: json.result.objectId,
      adminSymptoms: json.result.symptoms.map((item) => ({symptoms: item})),
    },
  };
}

export function fixGetManyPlans(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      adminSymptoms: resource.symptoms.map((item) => ({symptoms: item})),
    })),
  };
}

export function fixCreatePlanId(params, json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function planMapperGetOneResponse(json) {
  return {
    data: {
      ...json.result,
      id: json.result.objectId,
      adminSymptoms: json.result.symptoms.map((item) => ({symptoms: item})),
    },
  };
}
