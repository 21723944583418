import {stringify} from "query-string";
import {SERVER} from "../constants";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {blogCategoryMapperListResponse} from "../panels/blogCategory/blogCategoryMapper";
import {adviceListResponse} from "../panels/advice/adviceMapper";
import {blogMapperListResponse} from "../panels/blog/blogMapper";
import {userMapperListResponse} from "../panels/user/userMapper";
import {configMapperListResponse} from "../panels/config/configMapper";
import {periodMapperListResponse} from "../panels/period/periodMapper";
import {codesMapperListResponse} from "../panels/codes/codeMapper";
import {avatarMapperListResponse} from "../panels/avatar/avatarMapper";
import {childWeekMapperListResponse} from "../panels/childWeek/childWeekMapper";
import {taskMapperListResponse} from '../panels/task/taskMapper'
import {planMapperListResponse} from '../panels/plan/planMapper'

export function getList(resource, params) {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  let sortKey = field;
  let sortOrder = order;
  if (
    [
      "advice",
      "user",
      "periods",
      "codes",
      "blog",
      "blogCategory",
      "task",
      "plan",
      "adminUsers",
      "avatar",
      "userVariant",
      "childWeek",
    ].includes(resource) &&
    sortKey === "id"
  ) {
    sortKey = "objectId";
  }

  let from = JSON.stringify((page - 1) * perPage)
  let to = JSON.stringify(page * perPage - 1)
  let range = JSON.stringify([(page - 1) * perPage, page * perPage - 1])

  if (resource === "plan") {
    from = 0
    to = 1000
  }

  const query = {
    range: range,
    sort: JSON.stringify([sortKey, sortOrder]),
    from: from,
    to: to,
    filter: JSON.stringify(params.filter),
    phoneNumber: params.filter.phoneNumber,
  };
  const url = `${SERVER}/${MAP_URL[resource]}?${stringify(query)}`;

  return HttpClient(url).then(({headers, json}) => {
    if (resource === "advice") {
      return adviceListResponse(json, headers);
    } else if (resource === "blogCategory") {
      return blogCategoryMapperListResponse(json, headers);
    } else if (resource === "blog") {
      return blogMapperListResponse(json, headers);
    } else if (resource === "plan") {
      return planMapperListResponse(json, headers);
    } else if (resource === "task") {
      return taskMapperListResponse(json, headers);
    } else if (resource === "user") {
      return userMapperListResponse(json, headers);
    } else if (resource === "config") {
      return configMapperListResponse(json, headers);
    } else if (resource === "periods") {
      return periodMapperListResponse(json, headers);
    } else if (resource === "codes") {
      return codesMapperListResponse(json, headers);
    } else if (resource === "avatar") {
      return avatarMapperListResponse(json, headers);
    } else if (resource === "childWeek") {
      return childWeekMapperListResponse(json, headers);
    }

    return {
      data: json.map((resource) => ({
        ...resource,
        id: resource.phoneNumber,
      })),
      total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
    };
  });
}
