import {SERVER} from "../constants";
import {stringify} from "query-string";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {fixGetManyBlogCategories} from "../panels/blogCategory/blogCategoryMapper";
import {fixGetManyUsers} from "../panels/user/userMapper";
import {fixGetManyAdmins} from "../panels/adminUsers/adminUsersMapper";
import {fixGetManyAvatars} from "../panels/avatar/avatarMapper";
import {fixGetManyPlans} from '../panels/plan/planMapper'

export function getMany(resource, params) {
  console.log("getManyReference", resource, params);

  let ids = [];
  if (params.ids) {
    ids = params.ids;
  }
  const query = {
    filter: JSON.stringify({id: params.objectId, objectId: ids}),
  };
  const url = `${SERVER}/${MAP_URL[resource]}?${stringify(query)}`;
  return HttpClient(url).then(({json}) => {
    if (resource === "blogCategory") {
      return fixGetManyBlogCategories(json);
    } else if (resource === "plan") {
      return fixGetManyPlans(json);
    } else if (resource === "user") {
      return fixGetManyUsers(json);
    } else if (resource === "adminUsers") {
      return fixGetManyAdmins(json);
    } else if (resource === "avatar") {
      return fixGetManyAvatars(json);
    }

    return {
      data: {...json, id: json.objectId},
    };
  });
}
